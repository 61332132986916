$logoFamily: "Rubik";
$titleFmaily: "Rubik";
$subTitleFmaily: "Rubik";
$txtFamily: "Rubik";

// Size
$tiny: 10px;
$small: 11px;
$medium: 13px;
$large: 14px;
$xlarge: 16px;
$xxlarge: 18px;
$xxxlarge: 60px;

h1,
h2,
h3,
h4,
h5 {
  color: $txt;
  font-weight: 500;
}

#inner-container {
  h1 {
    color: black;
    font-size: $xxlarge;
  }
}

h1 {
  font-family: $titleFmaily;
  letter-spacing: 10px !important;
  text-transform: uppercase;
  font-size: 36px;
  font-weight: 400;
  line-height: 36px;
}

h2 {
  font-family: $titleFmaily;
  letter-spacing: 3px !important;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 16px;
}

h4 {
  font-family: $subTitleFmaily;
  letter-spacing: 3px;
}

p,
li,
a,
pre {
  font-family: $txtFamily;
  letter-spacing: 1px;
  color: $txt;
  font-weight: 400;
}

.form-text {
  color: grey;
}

.rotated {
  writing-mode: tb-rl;
  transform: rotate(-180deg);
}

.rotated-invert {
  writing-mode: vertical-rl;
  transform: rotate(0);
}

@media only screen and (max-width: 768px) {
  .rotated,
  .rotated-invert {
    writing-mode: horizontal-tb;
    transform: rotate(0);
  }
}
